// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-not-found-template-js": () => import("./../../../src/templates/NotFoundTemplate.js" /* webpackChunkName: "component---src-templates-not-found-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-popup-template-js": () => import("./../../../src/templates/PopupTemplate.js" /* webpackChunkName: "component---src-templates-popup-template-js" */)
}

